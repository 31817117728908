import { ThemeProvider, createTheme } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import ServiceWorkerWrapper from './Components/ServiceWorker';
import { CaptchaProvider } from '@contexts/captcha';
import { AppContext } from '@contexts/AppContext';
import React, { useRef, lazy, useState, useLayoutEffect, useEffect, Suspense } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import WebFont from 'webfontloader';
import { setIsThinClient, getStorageTheme, setStorageTheme } from './Storage/userStorage';
import defaultThemeJson from './Assets/Themes/default.json';

// main user routine, is included on main initial chunk/bundle
import Conversation from './Pages/Conversation/Consent';

// Lazy load components
const Console = lazy(() => import('./Pages/Console/Console'));
const Login = lazy(() => import('./Pages/Console/Login'));
const MorganWebHome = lazy(() => import('./Pages/Home/MorganWebHome'));
const Unsubscribe = lazy(() => import('./Pages/Unsubscribe'));
const OrderUpdate = lazy(() => import('./Pages/OrderUpdate'));
const WidgetTest = lazy(() => import('./Pages/WidgetTest'));

const defaultTheme = createTheme(defaultThemeJson as any);

const IOS_APP_STORE_URL = 'https://apps.apple.com/us/app/morgan-empathetic-technology/id1667401139';
const ANDROID_APP_STORE_URL =
  'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.swmc.morganthinclientandroid';

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700', 'Poppins:400,500,600,800', 'Barlow Condensed:400'],
  },
});

const availableThemes = new Set(['CSFNCE000', 'LEADFC000', 'USMRBK000', 'USMTHM000']);

const LazyRoute = (Component) => (
  <Suspense fallback={<></>}>
    <Component />
  </Suspense>
);

// This removes the console logs for the specified environments.
// The console.error was left to allow errors to be reported by users via the console if needed.
if (['swmc'].includes(process.env.REACT_APP_ENVIRONMENT)) {
  console.debug = () => {};
  console.log = () => {};
  console.warn = () => {};
}

export const App = () => {
  /*
   * This function gets the query parameters and checks to see if the user is
   * requesting a specific theme. If they are, we attempt to load it from the
   * Themes directory. If there is no matching theme then we simply keep using
   * the default!
   */
  const [searchParams] = useSearchParams();

  // new UI Version available Reload Button/Banner state variable
  const [showReloadButton, setShowReloadButton] = useState(false);
  const serviceWorkerWrapperRef = useRef(null);
  const [theme, setTheme] = useState(defaultTheme);

  useLayoutEffect(() => {
    console.warn(`Environment: ${process.env.REACT_APP_ENVIRONMENT}`);

    const setCssVariables = (obj, parentKey = '') => {
      for (let key in obj) {
        let newKey = parentKey + key.charAt(0).toUpperCase() + key.slice(1);

        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          setCssVariables(obj[key], newKey);
        } else {
          const cssVariableName = `--${newKey.charAt(0).toLowerCase() + newKey.slice(1)}`;
          document.body.style.setProperty(cssVariableName, obj[key]);
        }
      }
    };

    setCssVariables(theme.palette);

    document.body.style.setProperty('--typographyFontFamily', theme.typography.fontFamily);
  }, [theme]);

  // Use custom URL scheme to request users to open app if already installed
  // The app opens to the same link that the user clicked.
  // If the app is not installed, redirect user to the app listing on the
  // Apple App Store or Google Play Store to install the AngelAi app
  useEffect(() => {
    const t = searchParams.get('t');
    const company = searchParams.get('company');
    const branch = searchParams.get('branch');

    const themeName = t || (company && branch ? company + branch : 'default');

    if (availableThemes.has(themeName)) {
      import('./Assets/Themes/' + themeName + '.json').then((data) => {
        const newTheme = createTheme(data);
        setTheme(newTheme);
        if (themeName !== "default" ){
          setStorageTheme(themeName);
          console.log('Theme updated to: ', themeName)
        }
      });
    } 
      else if (getStorageTheme()){
        console.log('Using existing theme from session:', getStorageTheme())
        import('./Assets/Themes/' + getStorageTheme() + '.json').then((data) => {
          const newTheme = createTheme(data);
          setTheme(newTheme);
        })
      }
      else {
      setTheme(defaultTheme);
    }

    // Attempt to detect Thin Client by looking for the `thinClientKey` query
    // parameter:
    const tcKey = searchParams.get('thinClientKey');
    if (tcKey === process.env.REACT_APP_THIN_CLIENT_KEY) {
      setIsThinClient('true');
    }

    const userAgent = navigator.userAgent;
    const isIAB = /FBAN|FBAV|LinkedInApp|Instagram|Twitter/.test(userAgent);
    if (!isIAB) {
      return;
    }

    const isIos = /iPod|iPhone|iPad/.test(userAgent);
    const isAndroid = /android/.test(userAgent);
    // If user clicks an AngelAi link with the `/ce` pathname, redirect
    // URL will be `angelai://ce` which requests the user to open up AngelAi.
    // The app then handles the `angelai://ce` URL and serves the following
    // link `https://www.swmc.com/angelai/ce`
    // If theres no pathname, redirect URL will be `angelai://conversation`
    // This is necessary since `angelai://` alone will not redirect the user
    const base = 'angelai://';
    const pathname = window.location.pathname.slice(1) || 'conversation';
    const redirect = base + pathname;

    window.location.replace(redirect);

    const openAppStore = () => {
      if (isIos) {
        window.location.replace(IOS_APP_STORE_URL);
      }
      if (isAndroid) {
        window.location.replace(ANDROID_APP_STORE_URL);
      }
    };

    // If the user doesn't have the AngelAi app installed, redirect them to
    // the store after a short delay
    setTimeout(openAppStore, 25);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <CaptchaProvider>
        <ThemeProvider theme={theme}>
          <ServiceWorkerWrapper
            setShowReloadButton={setShowReloadButton}
            showReloadButton={showReloadButton}
            ref={serviceWorkerWrapperRef}
          />
          <AppContext.Provider value={{ showReloadButton, setShowReloadButton, serviceWorkerWrapperRef }}>
            <DndProvider backend={HTML5Backend}>
              <Routes>
                <Route path="/conversation" element={<Conversation />} />
                <Route path="/conversation/:hashkey" element={<Conversation />} />
                <Route path="/" element={<Conversation />} />
                <Route path="/notices" element={LazyRoute(MorganWebHome)} />
                <Route path="/notices/:hashkey" element={LazyRoute(MorganWebHome)} />
                <Route path="/invite/:hashkey" element={<Conversation />} />
                <Route path="/invite" element={<Conversation />} />
                <Route path="/reply/:hashkey" element={<Conversation />} />
                <Route path="/reply" element={<Conversation />} />
                <Route path="/uploadDocuments/:hashkey" element={<Conversation />} />
                <Route path="/uploadDocuments" element={<Conversation />} />
                <Route path="/console" element={LazyRoute(Console)} />
                <Route path="/console/:hashkey" element={LazyRoute(Console)} />
                <Route path="/console/login" element={LazyRoute(Login)} />
                <Route path="/console/login/:hashkey" element={LazyRoute(Login)} />
                <Route path="/unsubscribe/:hashkey" element={LazyRoute(Unsubscribe)} />
                <Route path="/unsubscribe" element={<Conversation />} />
                <Route path="/order/:hashkey" element={LazyRoute(OrderUpdate)} />
                <Route path="/order" element={<Conversation />} />
                <Route path="/widget" element={<Conversation />} />
                <Route path="/widget-test" element={LazyRoute(WidgetTest)} />
                <Route path="/:referrer" element={<Conversation />} />
                <Route path="/:referrer/:params" element={<Conversation />} />
              </Routes>
            </DndProvider>
          </AppContext.Provider>
        </ThemeProvider>
      </CaptchaProvider>
    </StyledEngineProvider>
  );
};
